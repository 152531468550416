import { ReactComponent as LightSvg } from '../../assets/dexco/otc/light.svg'
import { ReactComponent as ExfinitoSvg } from '../../assets/dexco/otc/otc-exfinito.svg'
import { BiChevronRight, BiSolidBarChartSquare } from 'react-icons/bi'
import Text from '../../core/utils/Text'
import { useOtc } from './useOtc'
import { Link } from 'react-router-dom'
import CryptoCoinSelect from './CryptoCoinSelect'
import ToomanRow from './ToomanRow'
import { useRunAfterUpdate } from '../../core/hooks/useRunAfterUpdate'
import { onInputValueChangeUtil } from '../../core/utils/useInputValueChange'
import { useMainContext } from '../../core/contexts/main'
import { deformatNumber, formatNumber } from '../../core/utils/common'
import OtcInvoiceModal from '../modals/OtcInvoiceModal'
import ModalLayout from '../layouts/ModalLayout'
import OtcErrorModal from '../modals/OtcErrorModal'
import MobileModal from '../modals/MobileModal'
import { useWindowSize } from '../../core/hooks/useWindowSize'
import { useRef, useState } from 'react'
import TradeChart from './chart/Chart'

const OtcProVersion = () => {
	const { width } = useWindowSize()
	const {
		main: { theme },
	} = useMainContext()
	const types = ['buy', 'sell']

	const {
		profile,
		balance,
		onBalanceClick,
		coin,
		price,
		onPriceChange,
		volume,
		onVolumeChange,
		setCoin,
		onAction,
		modal,
		toomanCoin,
		onClose,
		limits,
		type,
		setType,
		rate,
		change,
	} = useOtc()

	const afterUpdate = useRunAfterUpdate()
	const onChange = (e, inputType) => {
		const value = onInputValueChangeUtil(e, afterUpdate)
		if (inputType === 'first') {
			type === 'buy' ? onPriceChange(value) : onVolumeChange(value)
		} else {
			type === 'sell' ? onPriceChange(value) : onVolumeChange(value)
		}
	}

	const otcRef = useRef()
	const [chartOpen, setChartOpen] = useState(false)

	return (
		<div className={'mt-10 flex items-center justify-center gap-2'}>
			{chartOpen ? (
				width > 1024 ? (
					<div
						style={{ width: otcRef?.current?.clientWidth, height: otcRef?.current?.clientHeight }}
					>
						<TradeChart coin={coin} />
					</div>
				) : (
					<MobileModal isOpen={true} onClose={() => setChartOpen(false)}>
						<div className={'h-[520px]'}>
							<TradeChart coin={coin} />
						</div>
					</MobileModal>
				)
			) : null}

			<div
				ref={otcRef}
				className={
					'lg:w-[500px] w-full dark:bg-dark bg-white relative px-3 py-5 lg:p-5 rounded-xl overflow-hidden shadow-md dark:border-[1px] border-[#23262F99]'
				}
			>
				{theme === 'dark' ? (
					<>
						<div
							className={'absolute top-0 left-[2%] w-[96%] z-[10] h-[4px] rounded-xl'}
							style={{
								background:
									'linear-gradient(to right, #23262F10 0%, #0673F190 65%, #23262F10 100%)',
							}}
						/>

						<div className={'absolute top-0 left-0'}>
							<LightSvg />
						</div>

						<div
							style={{
								background: `radial-gradient(50% 50% at 50% 50%, rgba(94, 148, 255, 0.24) 0%, rgba(49, 94, 255, 0) 100%)`,
							}}
							className={'opacity-60 absolute z-[5] left-[-120px] top-[-350px] w-[700px] h-[700px]'}
						></div>
					</>
				) : null}

				<div
					className={
						'absolute z-[12] text-blue-600 cursor-pointer left-[12px] top-[20px] border-[1px] dark:border-[#353945] px-3 py-2 rounded-md'
					}
					onClick={() => setChartOpen((state) => !state)}
				>
					<BiSolidBarChartSquare size={24} />
				</div>

				<div className={'flex items-center justify-center mt-5'}>
					<ExfinitoSvg width={width > 1024 ? '344px' : '100%'} height={'64px'} />
				</div>

				<div className={'flex flex-col lg:p-5 py-3 relative z-[20]'}>
					<div
						className={
							'dark:bg-[#23262F] bg-[#DBDBDB] w-full h-[40px] rounded-lg p-1 grid grid-cols-2'
						}
					>
						{types.map((t) => {
							const active = type === t

							let bgColor = ''
							if (theme === 'dark') bgColor = 'bg-[#353945]'
							else {
								if (type === 'buy') bgColor = 'bg-[#00A693] text-white'
								else bgColor = 'bg-[#FF6E76] text-white'
							}

							return (
								<div
									key={t}
									className={`
                                        ${active && bgColor}
                                        transition cursor-pointer rounded-lg flex items-center justify-center h-full w-full`}
									onClick={() => setType(t)}
								>
									<Text tid={`${t}-request`} className={'text-sm'} />
								</div>
							)
						})}
					</div>

					<div className={'flex items-center justify-between gap-2 mt-5'}>
						<div className={'flex items-center gap-2 text-xs'} onClick={onBalanceClick}>
							<Text tid={'my-balance'} />
							<span>{balance}</span>
						</div>
						<Link to={'/wallets'}>
							<div className={'flex items-center gap-1 text-sm text-[#00A693] cursor-pointer'}>
								<span>+</span>
								<Text tid={'increase-balance'} />
							</div>
						</Link>
					</div>

					<div className={'flex flex-col gap-2 mt-5'}>
						<Text tid={'you-pay'} />
						<div className={'flex items-center gap-2'}>
							<div
								className={
									'w-[20%] min-w-[124px] h-[48px] rounded-md dark:bg-[#23262F] bg-[#F1F1F1] cursor-pointer'
								}
							>
								{type === 'buy' ? (
									<ToomanRow />
								) : (
									<CryptoCoinSelect type={type} coin={coin} onChange={setCoin} />
								)}
							</div>
							<div
								className={
									'w-[80%] h-[48px] dark:bg-[#23262F] bg-[#F1F1F1] flex items-center justify-center px-4 rounded-md'
								}
							>
								<input
									className={'w-full bg-transparent'}
									value={type === 'buy' ? (price ? price : '') : volume ? volume : ''}
									onChange={(e) => onChange(e, 'first')}
								/>
							</div>
						</div>
					</div>

					<div className={'flex flex-col gap-2 mt-5'}>
						<Text tid={'you-recieve'} />
						<div className={'flex items-center gap-2'}>
							<div
								className={
									'w-[20%] min-w-[124px] h-[48px] rounded-md dark:bg-[#23262F] bg-[#F1F1F1] cursor-pointer'
								}
							>
								{type === 'sell' ? (
									<ToomanRow />
								) : (
									<CryptoCoinSelect type={type} coin={coin} onChange={setCoin} />
								)}
							</div>
							<div
								className={
									'w-[80%] h-[48px] dark:bg-[#23262F] bg-[#F1F1F1] flex items-center justify-center px-4 rounded-md'
								}
							>
								<input
									className={'w-full bg-transparent'}
									value={type === 'sell' ? (price ? price : '') : volume ? volume : ''}
									onChange={(e) => onChange(e, 'second')}
								/>
							</div>
						</div>
					</div>

					<div className='flex flex-col gap-3 mt-6 text-sm'>
						<div
							className={
								'dark:bg-[#202A44] flex justify-between items-center rounded-md h-[40px] px-5 bg-[#CCD2E3]'
							}
						>
							<Text tid={'exchange-rate'} />
							<span>{formatNumber(rate, { type: coin?.id })}</span>
						</div>
						<div
							className={
								'dark:bg-[#202A44] flex justify-between items-center rounded-md h-[40px] px-5 bg-[#CCD2E3]'
							}
						>
							<Text tid={'24h-change'} />
							<div
								className={`px-2 py-1 rounded-md ${parseFloat(change) === 0 && 'bg-gray-500/10'} ${parseFloat(change) > 0 && 'bg-green-500/20 text-green-600'
									}  ${parseFloat(change) < 0 && 'bg-red-500/20 text-red-500'}`}
							>
								<span dir={'ltr'}>{change} %</span>
							</div>
						</div>
					</div>

					<div
						className={`mt-5 rounded-md cursor-pointer h-[43px] w-full flex items-center justify-center text-white ${type === 'buy' ? 'bg-[#00A693]' : 'bg-[#FF6E76]'
							}`}
						onClick={() => onAction(type)}
					>
						<Text tid={type} />
					</div>

					<div className={'mt-5 grid grid-cols-2 gap-3 text-xs'}>
						<div
							className={
								'cursor-pointer border-[1px] border-slate-500 border-opacity-20 rounded-md h-[38px] w-full flex items-center gap-2 justify-center'
							}
						>
							<Text tid={'otc-history'} />
							<BiChevronRight className={'rotate-180'} size={20} />
						</div>

						<div
							className={
								'cursor-pointer border-[1px] border-slate-500 border-opacity-20 rounded-md h-[38px] w-full flex items-center gap-2 justify-center'
							}
						>
							<Text tid={'buy-sell-tutorials'} />
							<BiChevronRight className={'rotate-180'} size={20} />
						</div>
					</div>
				</div>
			</div>

			{modal === 'otc' ? (
				width > 1024 ? (
					<ModalLayout open={true} onClose={onClose} width={'480px'}>
						<OtcInvoiceModal
							isModalOpen={true}
							state={type}
							firstAsset={{
								info: type === 'buy' ? toomanCoin : coin,
								amount: type === 'buy' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'buy' ? price : volume,
							}}
							secondAsset={{
								info: type === 'sell' ? toomanCoin : coin,
								amount: type === 'sell' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'sell' ? price : volume,
							}}
							onClose={onClose}
							coin={coin}
						/>
					</ModalLayout>
				) : (
					<MobileModal isOpen={true} onClose={onClose}>
						<OtcInvoiceModal
							state={type}
							firstAsset={{
								info: type === 'buy' ? toomanCoin : coin,
								amount: type === 'buy' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'buy' ? price : volume,
							}}
							secondAsset={{
								info: type === 'sell' ? toomanCoin : coin,
								amount: type === 'sell' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'sell' ? price : volume,
							}}
							onClose={onClose}
							coin={coin}
						/>
					</MobileModal>
				)
			) : (
				<ModalLayout open={modal !== 'close'} onClose={onClose} width={'480px'}>
					<OtcErrorModal
						onClose={onClose}
						limit={limits}
						coin={coin}
						type={modal}
						tradeType={type}
					/>
				</ModalLayout>
			)}
		</div>
	)
}

export default OtcProVersion
