import { memo, useMemo } from 'react'
import { SymbolOverview } from 'react-ts-tradingview-widgets'
import { useMainContext } from '../../../core/contexts/main'

const TradeChart = ({ coin }) => {
	const {
		main: { theme },
	} = useMainContext()
	const pair = useMemo(() => {
		if (coin?.id) {
			if (coin.id === 'usdt') return `COINBASE:${coin?.id?.toUpperCase()}USD|1M`
			return `KUCOIN:${coin?.id?.toUpperCase()}USDT|1M`
		}
		return null
	}, [coin])

	if (!pair) return

	return (
		<div className={'dark:bg-dark bg-white lg:shadow rounded-md h-full overflow-hidden'}>
			<SymbolOverview
				symbols={[[coin.name, pair]]}
				colorTheme={theme}
				autosize
				chartType='candlesticks'
				scalePosition={'right'}
				isTransparent
			/>
		</div>
	)
}

export default memo(TradeChart)
