import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated } from '@react-spring/web'
import styled, { css } from 'styled-components'

export default function MobileModal({ isOpen, children, onClose, noPadding = false, hasShadow = true, type = 'default' }) {
	const modalRoot = document.getElementById('mobile-modal-root')

	const animation = useSpring({
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? `translateY(0%)` : `translateY(100%)`,
		config: { tension: 300, friction: 30 },
	})

	useEffect(() => {
		// Lock body scroll when modal is open
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	const handleOverlayClick = (e) => {
		if (e.target.id === 'modal-overlay') {
			onClose()
		}
	}

	if (!isOpen) return null

	return ReactDOM.createPortal(
		<Overlay id='modal-overlay' onClick={handleOverlayClick}>
			<animated.div style={animation}>
				<ModalWrapper className={`bg-white dark:bg-dark`}>
					<NotchWrapper>
						<Notch type={type} />
						<div
							style={{
								display: hasShadow ? "" : 'none',
								background: `radial-gradient(50% 50% at 50% 50%, ${type === 'warn' ? "#FF7B7340" : "#0673F140"} 0%, rgba(49, 94, 255, 0) 100%)`,
							}}
							className={
								'opacity-60 absolute z-[5] left-[50%] translate-x-[-50%] top-[-350px] w-full h-[700px] rounded-br-[2rem] rounded-bl-[2rem]'
							}
						></div>
					</NotchWrapper>
					<Padding noPadding={noPadding}>
						{children}
					</Padding>
				</ModalWrapper>
			</animated.div>
		</Overlay>,
		modalRoot
	)
}

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: flex-end;
	z-index: 99999;
`

const ModalWrapper = styled.div`
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100vw;
	max-height: 70vh;
	overflow-y: auto;
	position: relative;
	direction: rtl;
	scrollbar-width: none;
`

const Padding = styled.div`
		position: relative;

		${(props) =>
		!props.noPadding &&
		css`
			padding-inline: 20px;
			padding-block: 10px 30px;
		`};
`
const NotchWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: sticky;
	top: 0;
`

const Notch = styled.div`
	width: 100%;
	height: 5px;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	background: linear-gradient(to right, #23262F10 0%, ${props => props.type === 'warn' ? "#FF7B73" : "#0673F190"} 70%, #23262F10 100%);
`
