import { useMainContext } from '../../core/contexts/main'
import { useRunAfterUpdate } from '../../core/hooks/useRunAfterUpdate'
import { useWindowSize } from '../../core/hooks/useWindowSize'
import { deformatNumber } from '../../core/utils/common'
import { onInputValueChangeUtil } from '../../core/utils/useInputValueChange'
import ModalLayout from '../layouts/ModalLayout'
import MobileModal from '../modals/MobileModal'
import OtcErrorModal from '../modals/OtcErrorModal'
import OtcInvoiceModal from '../modals/OtcInvoiceModal'
import { useOtc } from './useOtc'
import { ReactComponent as LightSvg } from '../../assets/dexco/otc/light.svg'
import { ReactComponent as ExfinitoSvg } from '../../assets/dexco/otc/otc-exfinito.svg'
import { BiChevronLeft } from 'react-icons/bi'
import Text from '../../core/utils/Text'
import { Link } from 'react-router-dom'
import CryptoCoinSelect from './CryptoCoinSelect'

const OtcClassicVersion = () => {
	const { width } = useWindowSize()
	const {
		main: { theme },
	} = useMainContext()
	const types = ['buy', 'sell']

	const {
		profile,
		balance,
		onBalanceClick,
		coin,
		price,
		onPriceChange,
		volume,
		onVolumeChange,
		setCoin,
		onAction,
		modal,
		toomanCoin,
		onClose,
		limits,
		type,
		setType,
		rate,
		change,
	} = useOtc()

	const afterUpdate = useRunAfterUpdate()
	const onChange = (e, inputType) => {
		const value = onInputValueChangeUtil(e, afterUpdate)
		if (inputType === 'first') {
			type === 'buy' ? onPriceChange(value) : onVolumeChange(value)
		} else {
			type === 'sell' ? onPriceChange(value) : onVolumeChange(value)
		}
	}

	return (
		<div>
			<div className={'mt-10 pb-10'}>
				<div
					className={
						'w-full dark:bg-dark bg-white relative p-5 rounded-xl mx-auto overflow-hidden shadow-md dark:border-[1px] border-[#23262F99]'
					}
				>
					{theme === 'dark' ? (
						<>
							<div
								className={'absolute top-0 left-[25%] w-[50%] z-[10] h-[4px] rounded-xl'}
								style={{
									background:
										'linear-gradient(to right, #23262F10 0%, #0673F190 65%, #23262F10 100%)',
								}}
							/>

							<div className={'absolute top-0 left-[50%] translate-x-[-50%]'}>
								<LightSvg />
							</div>

							<div
								style={{
									background: `radial-gradient(50% 50% at 50% 50%, rgba(94, 148, 255, 0.24) 0%, rgba(49, 94, 255, 0) 100%)`,
								}}
								className={
									'opacity-60 absolute z-[5] left-[50%] translate-x-[-50%] top-[-350px] w-[700px] h-[700px]'
								}
							></div>
						</>
					) : null}

					<Link to={'/otc-transactions'}>
						<div
							className={
								'absolute flex items-center gap-1 z-[12] cursor-pointer text-xs left-[12px] top-[20px] border-[1px] dark:border-[#353945] px-3 py-2 rounded-md'
							}
						>
							<Text tid={'transaction-history'} />
							<BiChevronLeft />
						</div>
					</Link>

					<div className={'flex items-center justify-center mt-5'}>
						<ExfinitoSvg width={'344px'} height={'64px'} />
					</div>

					<div className={'flex flex-col gap-3 relative z-[20] w-[450px] mt-10 mx-auto'}>
						<div
							className={'dark:bg-[#23262F] bg-[#DBDBDB] h-[40px] rounded-lg p-1 grid grid-cols-2'}
						>
							{types.map((t) => {
								const active = type === t

								let bgColor = ''
								if (theme === 'dark') bgColor = 'bg-[#353945]'
								else {
									if (type === 'buy') bgColor = 'bg-[#00A693] text-white'
									else bgColor = 'bg-[#FF6E76] text-white'
								}

								return (
									<div
										key={t}
										className={`
                                        ${active && bgColor}
                                        transition cursor-pointer rounded-lg flex items-center justify-center h-full w-full`}
										onClick={() => setType(t)}
									>
										<Text tid={`${t}-request`} className={'text-sm'} />
									</div>
								)
							})}
						</div>

						<div
							className={
								'dark:bg-[#202A44] text-sm flex justify-between items-center rounded-md h-[40px] px-5 bg-[#CCD2E3]'
							}
						>
							<Text tid={'my-balance'} />
							<span>{balance}</span>
						</div>
					</div>

					<div
						className={'grid grid-cols-3 text-sm gap-3 max-w-[80%] relative z-[20] mx-auto mt-8'}
					>
						<div className={'flex flex-col gap-2 w-full'}>
							<Text tid={'select-coin'} />
							<div
								className={
									'w-full h-[48px] rounded-md dark:bg-[#23262F] bg-[#F1F1F1] cursor-pointer'
								}
							>
								<CryptoCoinSelect between type={type} coin={coin} onChange={setCoin} />
							</div>
						</div>

						<div className={'flex flex-col gap-2 w-full'}>
							<Text tid={'you-pay'} />
							<div
								className={
									'w-full h-[48px] dark:bg-[#23262F] bg-[#F1F1F1] flex items-center justify-center px-4 rounded-md'
								}
							>
								<input
									className={'w-full bg-transparent'}
									value={`${type === 'buy' ? (price ? price : '') : volume ? volume : ''} ${
										type === 'buy' ? 'تومان' : coin?.fa
									}`}
									onChange={(e) => onChange(e, 'first')}
								/>
							</div>
						</div>

						<div className={'flex flex-col gap-2 w-full'}>
							<Text tid={'you-recieve'} />
							<div
								className={
									'w-full h-[48px] dark:bg-[#23262F] bg-[#F1F1F1] flex items-center justify-center px-4 rounded-md'
								}
							>
								<input
									className={'w-full bg-transparent'}
									value={`${type === 'sell' ? (price ? price : '') : volume ? volume : ''} ${
										type === 'sell' ? 'تومان' : coin?.fa
									}`}
									onChange={(e) => onChange(e, 'second')}
								/>
							</div>
						</div>

						<div
							className={`my-5 col-span-3 rounded-md cursor-pointer h-[43px] w-full flex items-center justify-center text-white ${
								type === 'buy' ? 'bg-[#00A693]' : 'bg-[#FF6E76]'
							}`}
							onClick={() => onAction(type)}
						>
							<Text tid={type} />
						</div>
					</div>
				</div>
			</div>

			{modal === 'otc' ? (
				width > 1024 ? (
					<ModalLayout open={true} onClose={onClose} width={'480px'}>
						<OtcInvoiceModal
							isModalOpen={true}
							state={type}
							firstAsset={{
								info: type === 'buy' ? toomanCoin : coin,
								amount: type === 'buy' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'buy' ? price : volume,
							}}
							secondAsset={{
								info: type === 'sell' ? toomanCoin : coin,
								amount: type === 'sell' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'sell' ? price : volume,
							}}
							onClose={onClose}
							coin={coin}
						/>
					</ModalLayout>
				) : (
					<MobileModal isOpen={true} onClose={onClose}>
						<OtcInvoiceModal
							state={type}
							firstAsset={{
								info: type === 'buy' ? toomanCoin : coin,
								amount: type === 'buy' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'buy' ? price : volume,
							}}
							secondAsset={{
								info: type === 'sell' ? toomanCoin : coin,
								amount: type === 'sell' ? deformatNumber(price) : deformatNumber(volume),
								formattedNumber: type === 'sell' ? price : volume,
							}}
							onClose={onClose}
							coin={coin}
						/>
					</MobileModal>
				)
			) : (
				<ModalLayout open={modal !== 'close'} onClose={onClose} width={'480px'}>
					<OtcErrorModal
						onClose={onClose}
						limit={limits}
						coin={coin}
						type={modal}
						tradeType={type}
					/>
				</ModalLayout>
			)}
		</div>
	)
}

export default OtcClassicVersion
