import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	BadgeIcon,
	BalancesIcon,
	DashboardIcon,
	NotificationIcon,
	OtcTradeIcon,
	ProfileIcon,
	// SettingsIcon,
	SpotTradeIcon,
} from '../common/icons'
import Text from '../../core/utils/Text'
import { PiUserFocus } from 'react-icons/pi'
import { HOME } from '../../core/constants/urls'
import { useUnreadNotificationQuery } from '../../core/services/react-query/useNotificationsQuery'
import { FaChevronLeft, FaInstagram, FaMessage, FaPhone, FaTelegram } from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import { useAuthContext } from '../../core/contexts/auth'


export default function NewSidebar({
	isSidebarExpanded,
	toggleSidebar,
	openSideBar,
	closeSideBar,
}) {
	const { logout } = useAuthContext()
	const [activeLinkIndex, setActiveLinkIndex] = useState(-1)
	const { pathname } = useLocation()

	useEffect(() => {
		if (pathname === '/dashboard') setActiveLinkIndex(0)
		else if (pathname === '/otc') setActiveLinkIndex(1)
		else if (pathname === '/authentication') setActiveLinkIndex(2)
		else if (
			pathname.startsWith('/wallets') ||
			pathname === '/transaction-history' ||
			pathname === '/log'
		)
			setActiveLinkIndex(4)
		else if (pathname.startsWith('/profile')) setActiveLinkIndex(5)
		else if (pathname === '/notifications') setActiveLinkIndex(6)
	}, [pathname])

	const { data: notifications, isFetching: notificationsLoading } = useUnreadNotificationQuery(1)

	const renderedItems = LINKS.map((item, index) => {
		const { Icon } = item
		const NotificationIcon =
			!notificationsLoading && notifications?.data?.length > 0 ? (
				<div className='relative'>
					<Icon color={activeLinkIndex === index ? '#0773F1' : '#A6A9B9'} />
					<div className='absolute top-0 right-0 animate-pulse'>
						<BadgeIcon size={11} />
					</div>
				</div>
			) : (
				<Icon color={activeLinkIndex === index ? '#0773F1' : '#A6A9B9'} />
			)

		return (
			<li
				key={item.text}
				className={`flex items-center relative overflow-hidden ${isSidebarExpanded ? 'justify-start' : 'justify-center'}  ${activeLinkIndex !== index ? 'hover:bg-white/5' : ''
					}
                `}
			>
				{activeLinkIndex === index ?
				<div style={{ boxShadow: '0 0 10px rgb(59 130 246)' }} className={'h-[42px] w-[8px] bg-blue-500 absolute right-[-4px] rounded-xl'} />
				:
				null}
				<Link
					to={item.href}
				>
					{item.text === 'notifications' ? (
						NotificationIcon
					) : (
						<Icon color={activeLinkIndex === index ? '#0773F1' : '#A6A9B9'} />
					)}
					{isSidebarExpanded && <Text tid={item.text} className={'dark:text-white text-slate-800 text-sm'} />}
				</Link>
			</li>
		)
	})

	return (
		<Sidebar
			className={`bg-white dark:bg-dark overflow-x-hidden ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}
			// onMouseEnter={openSideBar}
			// onMouseLeave={closeSideBar}
		>
			<div 
			className={`absolute top-10 flex items-center 
				z-[100] left-[-19px] cursor-pointer dark:bg-[#242A3B] 
				bg-gray-light rounded-lg w-[38px] h-[64px] px-[2px]`} 
			onClick={toggleSidebar}
			>
				<FaChevronLeft size={17} className={`text-slate-500 ${isSidebarExpanded ? 'rotate-180' : 'rotate-0'} transition`} />
			</div>
			{/* <div className='py-5'>
				{isSidebarExpanded ? (
					<></>
				) : (
					<button className='toggle-button' onClick={toggleSidebar}>
						<FaChevronLeft size={22} />
					</button>
				)}
			</div> */}
			<div className={'pt-5 h-full flex justify-between flex-col gap-2'}>
				<nav>
					<ul>{renderedItems}</ul>
				</nav>

				<div className={'flex flex-col gap-5'}>
					{isSidebarExpanded ?
						<Link to={''} className={`border-[1px] text-xs border-blue-500 
							flex flex-col gap-2 justify-center rounded-md w-[80%]
							items-center h-[70px] mx-auto cursor-pointer dark:text-white text-slate-800`}>
							<span>ارتباط با تیم پشتیبانی</span>
							<div className={'flex items-center gap-2'}>
								{contactSupportIcons.map(Item => {
									return (
										<Item size={14} className={'dark:text-white text-slate-700'} />
									)
								})}
								</div>
						</Link>
					:
						<></>
					}
					<div className={`w-full cursor-pointer h-[60px] text-sm dark:text-slate-300
					 dark:bg-[#242A3B] bg-slate-600 rounded-b-xl flex items-center gap-2 justify-center`}
						onClick={logout}
					 >
						<CiLogout size={20} className={'rotate-180'} />
						{isSidebarExpanded ? 
							<span>خروج از حساب</span>	
							: null}
					</div>
				</div>


			
			</div>
		</Sidebar>
	)
}

const contactSupportIcons = [
	FaMessage, FaPhone, FaInstagram, FaTelegram
]

const Sidebar = styled.aside`
	position: fixed;
	top: 100px;
	right: 1rem;
	height: calc(100% - 100px - 2rem);
	color: white;
	transition: width 0.3s;
	z-index: 200;
	border-radius: 10px;

	&.expanded {
		width: 200px;
	}

	&.collapsed {
		width: 96px;
	}

	.toggle-button {
		cursor: pointer;
		margin: 1rem auto;
		display: flex;
		align-items: center;
	}

	nav ul {
		list-style-type: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		row-gap: 5px;
	}

	nav ul li a {
		display: flex;
		align-items: center;
		padding: 15px;
		cursor: pointer;
		gap: 5px;
		font-size: 0.8rem;
		transition: all 0.1s ease-in-out;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	nav ul li {
		transition: background-color 0.3s;
	}

	.icon {
		margin-left: 10px;
	}
`

const AuthenticationIcon = styled(PiUserFocus)`
	width: 24px;
	height: 24px;
`

export const LINKS = [
	{ text: 'dashboard', Icon: DashboardIcon, href: '/dashboard' },
	{ text: 'otc-trade', Icon: OtcTradeIcon, href: '/otc' },
	{ text: 'authentication', Icon: AuthenticationIcon, href: '/authentication' },
	{ text: 'spot-trade', Icon: SpotTradeIcon, href: '/trade' },
	{ text: 'your-balances', Icon: BalancesIcon, href: '/wallets' },
	{ text: 'user-profile', Icon: ProfileIcon, href: '/profile' },
	{ text: 'notifications', Icon: NotificationIcon, href: '/notifications' },
	// { text: 'account-settings', Icon: SettingsIcon, href: '#' },
]
