import Card from '../components/common/Card'
import NewLayout from '../components/layouts/NewLayout'
import { useWindowSize } from '../core/hooks/useWindowSize'
import { ItemData, ItemRow } from '../styles/newStyles/MobileModal.styled'
import { BuySellIcon } from '../components/common/icons'
import Text from '../core/utils/Text'
import Otc from '../components/otc/Otc'
import { useOtc } from '../components/otc/useOtc'
import OtcMarket from '../components/otc/OtcMarket'
import OtcHistory from '../components/OtcHistory'
import ROtcHistory from '../components/responsive/orders/ROtcHistory'
import { useState } from 'react'
import ModalLayout from '../components/layouts/ModalLayout'
import { TABLET_SIZE } from '../core/constants/common'
import TransactionDetails from '../components/transactions/TransactionDetails'
import OtcDetails from '../components/otc/OtcDetails'
import MobileModal from '../components/modals/MobileModal'
import UserOtcHistory from './UserOtcHistory'
import OtcProVersion from '../components/otc/OtcProVersion'
import OtcClassicVersion from '../components/otc/OtcClassicVersion'

export default function UserOtc() {
	const { width } = useWindowSize()
	const tabs = ['pro-version', 'classic-version']
	const [activeTab, setActiveTab] = useState('pro-version')

	return (
		<NewLayout>
			<div className={'lg:p-5 px-3 py-5'}>
				{width > 1024 ? (
					<div
						className={
							'dark:bg-dark bg-white shadow-md rounded-lg h-[92px] overflow-hidden w-full flex gap-5 lg:text-xl text-lg px-5'
						}
					>
						{tabs.map((t) => {
							const active = t === activeTab
							return (
								<div
									className={
										'relative cursor-pointer h-full flex items-center justify-center hover:bg-slate-500/10 rounded-md px-3'
									}
									key={t}
									onClick={() => setActiveTab(t)}
								>
									<Text tid={t} className={`${active ? 'dark:text-blue-200 text-blue-500' : ''}`} />
									{active ? (
										<div
											className={
												'absolute bottom-[-8px] left-0 h-[12px] w-full bg-[#0673F1] rounded-xl'
											}
											style={{ boxShadow: '-2px 0px 8.6px 0px #0673F1' }}
										></div>
									) : null}
								</div>
							)
						})}
					</div>
				) : null}

				{activeTab === 'pro-version' || width < 1024 ? <OtcProVersion /> : null}
				{activeTab === 'classic-version' && width >= 1024 ? <OtcClassicVersion /> : null}
			</div>
		</NewLayout>
	)
}
