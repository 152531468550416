import { motion } from "framer-motion"
import { LINKS } from "./NewSidebar"
import { Fragment, useEffect, useRef, useState } from "react"
import { HorizontalLine, ItemData, LinkItemRow } from "../../styles/newStyles/MobileModal.styled"
import Text from "../../core/utils/Text"
import { FaChevronLeft } from "react-icons/fa6"
import { useLocation } from "react-router-dom"
import useClickOutside from "../../core/hooks/useClickOutside"


const RespNavigationModal = ({ onClose }) => {

    const ref = useRef()
    useClickOutside(ref, onClose)

    const [activeLinkIndex, setActiveLinkIndex] = useState(-1)
    const { pathname } = useLocation()

    useEffect (() => {
        if (pathname === '/dashboard') setActiveLinkIndex(0)
        else if (pathname === '/otc') setActiveLinkIndex(1)
        else if (pathname === '/authentication') setActiveLinkIndex(2)
        else if (
            pathname.startsWith('/wallets') ||
            pathname === '/transaction-history' ||
            pathname === '/log'
        )
            setActiveLinkIndex(4)
        else if (pathname.startsWith('/profile')) setActiveLinkIndex(5)
        else if (pathname === '/notifications') setActiveLinkIndex(6)
        }, [pathname])

    const renderedItems = LINKS.map((item, index, array) => {
		const { Icon } = item
		return (
			<Fragment key={item.text}>
				<LinkItemRow to={item.href}>
					<ItemData>
						<Icon color={activeLinkIndex === index ? '#0773F1' : '#A6A9B9'} />
						<Text tid={item.text} className={'text-sm'} />
					</ItemData>
					<FaChevronLeft />
				</LinkItemRow>
				{index !== array.length - 1 && <HorizontalLine />}
			</Fragment>
		)
	})

    return (
        <motion.div
            animate={{ y: 0, transition: { type: 'ease' } }}
            exit={{ y: -600, transition: { type: 'ease' } }}
            initial={{ y: -600 }}
            className={'fixed top-0 z-[1000] left-0 w-screen max-h-[500px] overflow-y-auto dark:bg-dark bg-white p-5'}
            ref={ref}
        >
            <ul className='flex flex-col gap-y-2'>{renderedItems}</ul>
        </motion.div>
    )
}

export default RespNavigationModal