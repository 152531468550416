import { DText } from '../../styles/CommonStyles'
import Text from '../../core/utils/Text'

const ToomanRow = () => {
	return (
		<div className={`flex items-center justify-center w-full h-full gap-2`}>
			<img src={require('../../assets/images/tooman.png')} width={30} height={30} alt={'tooman'} />
			<DText main margin={'0 8px'}>
				<Text tid={'tooman'} />
			</DText>
		</div>
	)
}

export default ToomanRow
