import styled, { css } from 'styled-components'
import useClickOutside from "../../core/hooks/useClickOutside";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { variants } from "../../core/utils/common";


const ModalLayout = ({ width, children, onClose = () => { }, open, isStatic = false, hasShadow = true, type = 'default' }) => {
	const modalRef = useRef()
	useClickOutside(modalRef, () => {
		if (!isStatic) onClose()
	})

	return (
		<>
			{open ?
				<Body>
					<AnimatePresence exitBeforeEnter>
						{open && (
							<Wrapper
								ref={modalRef}
								variants={variants}
								initial='out'
								animate='in'
								exit='out'
								width={width}
							>
								<NotchWrapper>
									<Notch type={type} />
									<div
										style={{
											display: hasShadow ? "" : 'none',
											background: `radial-gradient(50% 50% at 50% 50%, ${type === 'warn' ? "#FF7B7340" : "#0673F140"} 0%, rgba(49, 94, 255, 0) 100%)`,
										}}
										className={
											'opacity-60 absolute z-[5] left-[50%] translate-x-[-50%] top-[-350px] w-full h-[700px] rounded-br-[2rem] rounded-bl-[2rem]'
										}
									></div>
								</NotchWrapper>
								<Padding>
									{children}
								</Padding>
							</Wrapper>
						)}
					</AnimatePresence>
				</Body>
				: null
			}
		</>

	)
}

const Body = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
		background: rgba(0, 0, 0, 0.5);

	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11111111111111;
`

const Wrapper = styled(motion.div)`
	width: 100%;
	max-width: ${(props) => props.width};
	background-color: ${(props) => props.theme.modalBg};
	border-radius: 20px;
	box-shadow: 0 0 10px black;
	z-index: 10;
`


const Padding = styled.div`
		position: relative;
		${(props) =>
		!props.noPadding &&
		css`
			padding-inline: 20px;
			padding-block: 10px 30px;
		`};
`

const NotchWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: sticky;
	top: 0;
`

const Notch = styled.div`
	width: 100%;
	height: 3px;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	background: linear-gradient(to right, #23262F10 0%, ${props => props.type === 'warn' ? "#FF7B73" : "#0673F190"} 70%, #23262F10 100%);
`

export default ModalLayout
