import styled from 'styled-components'
import {
	BalancesIcon,
	BottomBarBalancesIcon,
	BottomBarSettingsIcon,
	CardsIcon,
	ChartIcon,
	ContactsIcon,
	DashboardIcon,
	InviteFriendsIcon,
	SecurityIcon2,
	TradeIcon,
	UserEditIcon,
} from '../common/icons'
import { Link, useLocation } from 'react-router-dom'
import Text from '../../core/utils/Text'
import MobileModal from '../modals/MobileModal'
import { useEffect, useState } from 'react'
import SettingModal from '../modals/SettingModal'
import TradeModal from '../modals/TradeModal'
import { useMainContext } from '../../core/contexts/main'
import MenuModal from '../modals/MenuModal'
import { ReactComponent as AiNav } from "../../assets/dexco/nav/ai.svg"
import { ReactComponent as WalletNav } from "../../assets/dexco/nav/wallet.svg"
import { ReactComponent as ProfileNav } from "../../assets/dexco/nav/profile.svg"
import { ReactComponent as DashboardNav } from "../../assets/dexco/nav/dashboard.svg"
import { ReactComponent as ExfinitoSvg } from "../../assets/dexco/exfinito.svg"
import { animate, AnimatePresence, motion } from 'framer-motion'
import { BiChevronLeft } from 'react-icons/bi'

const PROFILE_LINKS = [
	{ href: '/profile', tid: 'account-overview', Icon: UserEditIcon },
	{ href: '/profile/cards&accounts', tid: 'cards-accounts', Icon: CardsIcon },
	{ href: '/profile/contacts', tid: 'my-contacts', Icon: ContactsIcon },
	{ href: '/profile/security', tid: 'account-security', Icon: SecurityIcon2 },
	// { href: '/profile/settings', tid: 'account-settings', Icon: SettingsIcon2 },
	{
		href: '/profile/invite-friends',
		tid: 'invite-friends',
		Icon: InviteFriendsIcon,
	},
]

export default function NewBottomBar() {
	const {
		main: { theme },
	} = useMainContext()

	const [activeTab, setActiveTab] = useState(0)

	const initialModal = { type: null, open: false }
	const [modal, setModal] = useState(initialModal)

	const openModal = (type) => setModal({ type, open: true })
	const closeModal = () => setModal(initialModal)

	const { pathname } = useLocation()

	const [isRotated, setIsRotated] = useState(false)
	const handleRotate = () => {
		setIsRotated(true)
		setTimeout(() => {
			setIsRotated(false)
		}, 2000)
	}
	useEffect(() => {
		if (pathname.includes('trade')) {
			handleRotate()
		}
	}, [pathname])

	useEffect(() => {
		if (modal.open) {
			closeModal()
		}
		if (pathname === '/dashboard') setActiveTab(0)
		else if (pathname === '/otc' || pathname.includes('trade')) setActiveTab(2)
		else if (pathname.startsWith('/wallets') || pathname === '/log') setActiveTab(3)
		else if (pathname === '/transaction-history') setActiveTab(1)
	}, [pathname])

	return (
		<>
			<div
				className={'fixed bottom-[42px] rounded-[50%] w-[56px] cursor-pointer z-[103] h-[56px] bg-[#0673F1] left-[50%] translate-x-[-50%] rotate-180'}
				style={{ boxShadow: '0px -3px 23.7px 0px #0673F1AB' }}
			>
				<div className={'w-full h-full flex items-center justify-center'}>
					<Link to={'/trade'}>
						<motion.div
							animate={{ rotate: isRotated ? 90 : 0 }}
							transition={{ type: "spring", stiffness: 500, damping: 10 }}
						>
							<ExfinitoSvg />
						</motion.div>
					</Link>
				</div>
			</div>
			<div
				className="w-[95%] fixed z-[102] bottom-[10px] left-[2.5%] h-[60px] rotate-180 bg-[#1F273C] rounded-md flex items-center justify-between"
				style={{
					maskImage: "radial-gradient(circle at 50% 0%, transparent 21%, black 21.5%)",
					WebkitMaskImage: "radial-gradient(circle at 50% 105%, transparent 21%, black 21.5%)",
				}}
			>
				<div className={'rotate-180 flex items-center w-[40%] justify-center gap-4'}>

					<div className={'cursor-pointer'}>
						<Link to={'/wallets'} className={`flex flex-col  gap-1 items-center justify-center 
					${pathname.includes('wallets') ? 'text-blue-500' : 'dark:text-slate-300 text-slate-700'}`}>
							<WalletNav width={22} />
							<span className={'text-[0.7rem]'}>تراکنش ها</span>
						</Link>
					</div>
					<div className={'cursor-pointer'}>
						<Link to={'/dashboard'} className={`flex flex-col  gap-1 items-center justify-center 
					${pathname.includes('dashboard') ? 'text-blue-500' : 'dark:text-slate-300 text-slate-700'}`}>
							<DashboardNav width={22} />
							<span className={'text-[0.7rem]'}>دکسکو</span>
						</Link>
					</div>

				</div>

				<div className={'fixed top-0 left-[50%] translate-x-[-50%] rotate-180'}>
					<span className={`text-xs ${pathname.includes('otc') || pathname.includes('trade') ?
						'text-blue-500' : 'dark:text-slate-300 text-slate-700'}`}>ترید</span>
				</div>

				<div className={'rotate-180 flex items-center w-[40%] justify-center gap-4'}>

					<div className={'cursor-pointer'}>
						<button onClick={openModal} className={`flex flex-col  gap-1 items-center justify-center 
					${pathname.includes('profile') ? 'text-blue-500' : 'dark:text-slate-300 text-slate-700'}`}>
							<ProfileNav width={22} />
							<span className={'text-[0.7rem]'}>پروفایل</span>
						</button>
					</div>
					<div className={'cursor-pointer'}>
						<Link to={'/ai'} className={`flex flex-col  gap-1 items-center justify-center 
					${pathname.includes('ai') ? 'text-blue-500' : 'dark:text-slate-300 text-slate-700'}`}>
							<AiNav width={22} />
							<span className={'text-[0.7rem]'}>هوش مصنوعی</span>
						</Link>
					</div>
				</div>
			</div>

			<MobileModal isOpen={modal.open} onClose={closeModal} noPadding hasShadow={false}>
				<div className='flex flex-col'>
					{
						PROFILE_LINKS.map(link => {
							const { Icon } = link
							return (
								<ProfileItem to={link.href}>
									<Icon />
									<Text tid={link.tid} />
									<BiChevronLeft size={22} className='mr-auto' />
								</ProfileItem>
							)
						})
					}
				</div>
			</MobileModal>
		</>

	)
}

const ProfileItem = styled(Link)`

	display: flex;
	justify-content: start;
	align-items: center;
	gap: 6px;
	padding: 20px 12px;
	border-radius: 8px;
	&:nth-child(odd){
		background-color: ${props => props.theme.secondaryBg};
	}
`